<template>
  <a-tabs :active-key="activeTab" @change="onTabChange">
    <a-tab-pane key="OpenAuthorization" tab="互动数据授权">
      <open-authorization-tab ref="OpenAuthorization" :author="author"></open-authorization-tab>
    </a-tab-pane>
    <a-tab-pane key="ServiceAuthorization" tab="直播数据授权">
      <service-authorization-tab ref="ServiceAuthorization" :author="author"></service-authorization-tab>
    </a-tab-pane>
  </a-tabs>
</template>
<style scoped>

</style>
<script>

import OpenAuthorizationTab from "./OpenAuthorizationTab";
import ServiceAuthorizationTab from "./ServiceAuthorizationTab";
import authorAuthApi from '@/api/authorAuth'

export default {
  components:{
    OpenAuthorizationTab,
    ServiceAuthorizationTab
  },
  props:{
    authorId:{
      type:String,
      required:true
    },
  },
  data(){
    return {
      author:{
        authorId: this.authorId
      },
      loading:false,
      activeTab: 'OpenAuthorization'
    }
  },
  methods:{
    init(){
      this.$route.query.activeTab && (this.activeTab = this.$route.query.activeTab)
      this.onTabChange(this.activeTab)
    },
    onTabChange(activeTab) {
      this.activeTab = activeTab
      this.$nextTick(() => this.load())
    },
    async load() {
      console.log(this.author.authorId)
      this.loading = true
      // this.$api.core.AuthorService.get(this.authorId).then(res => {
      //   this.author = res || {authorId: this.authorId}
      //   this.$refs[this.activeTab].$emit('init')
      // }).finally(()=>{
      //   this.loading = false
      // })

      try {
        const params = {
          authorId: this.authorId
        }
        const res = await authorAuthApi.fetchAuthorDetailKS(params)

        this.loading = true
        this.author = res || { authorId: this.authorId }
        this.$refs[this.activeTab].$emit('init')
      } catch (e) {
        console.error(e)
        this.loading = true
      }
    }
  },
  created() {
  },
  mounted() {
    this.$on('init',this.init)
  }
}
</script>
